import { Child } from '../../models/child.interface';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from '../../models/user.interface';
 
export const selectUserState = createFeatureSelector<User>('user');
 
export const selectUser = createSelector(
  selectUserState,
  (state: User) => state
);

export const selectUserId = createSelector(
  selectUserState,
  (state: User) => state.id
);

export const selectUserParentEmail = createSelector(
  selectUserState,
  (state: User) => state.parentEmail
);

export const selectUserRole = createSelector(
  selectUserState,
  (state: User) => state.role
);

export const selectUserIsFetchingChildrenStatus = createSelector(
  selectUserState,
  (state: User) => state.isFetchingChildren
);

export const selectUserChildren = createSelector(
  selectUserState,
  (state: User) => state.children
);

// TODO: Remove if not used in authGuard refactor
export const selectUserAuthStatus = createSelector(
  selectUserState,
  (state: User) => state.isAuth
);