
<mat-card class="w-80 flex items-center mt-20">
  <mat-card-header>
    <mat-card-title>
      <app-auth-title></app-auth-title>
    </mat-card-title>
    </mat-card-header>
    <mat-card-content>  
      <mat-divider></mat-divider>
      <div class="flex justify-center mt-2">
        <span>Parent Sign Up ONLY</span>
      </div> 
      <div class="flex justify-center w-64">
        <span class="text-red-500">{{ authError }}</span>
      </div> 
      <form [formGroup]="signUpForm" (ngSubmit)="onSignUp()">
        <div class="mt-6 w-64">
          <app-email-input [emailControl]="emailControl"></app-email-input>
          <app-password-input 
            [passwordControl]="passwordControl" 
            [isDisabled]="isLoading"
            [isCreate]="true">
          </app-password-input>
          <div class="flex justify-center">
            <app-submit-button 
            [isDisabled]="isLoading || signUpForm.invalid" 
            [text]="'Sign Up'">
          </app-submit-button>
          </div>   
        </div>  
      </form>
      <mat-divider></mat-divider>
    </mat-card-content>
    <mat-card-footer>
      <div class="flex justify-center mt-4">
        <p>Need to Confirm Parent Email?
          <span 
            class="cursor-pointer underline text-indigo-500 decoration-indigo-500" 
            routerLink="/auth/confirm-parent">
            Click Here!
          </span>
        </p>
      </div>
      <div class="flex justify-center">
        <p>Need to Sign In?
          <span 
            class="cursor-pointer underline text-indigo-500 decoration-indigo-500" 
            routerLink="/auth/sign-in">
            Click Here!
          </span>
        </p>
      </div>
    </mat-card-footer>  
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>  
</mat-card>
