<div class="flex items-center justify-center">
  <mat-card class="flex items-center m-6 w-80">
    <mat-card-header>
      <mat-card-title>
        Add Child
      </mat-card-title>
    </mat-card-header>
    <mat-card-content> 
      <mat-divider></mat-divider>
      <div class="flex justify-center mt-2">
        <span>Create Child Account</span>
      </div> 
      <div class="flex justify-center w-64">
        <span class="text-red-500">{{ addError }}</span>
      </div>
      <form [formGroup]="addChildForm" (ngSubmit)="onAddChild()">
        <div class="mt-6 w-64">
          <app-username-input [usernameControl]="usernameControl" [isAddChild]="true"></app-username-input>
          <app-password-input 
            [passwordControl]="passwordControl" 
            [isDisabled]="isLoading"
            [isCreate]="true">
          </app-password-input>
          <div class="flex justify-center">
            <app-submit-button 
              [isDisabled]="isLoading || addChildForm.invalid" 
              [text]="'Add Child'">
            </app-submit-button>
          </div>   
        </div>   
      </form>
      <mat-divider></mat-divider>
    </mat-card-content> 
    <mat-card-footer>
      <button 
        mat-stroked-button 
        class="mb-6 mt-6 w-64 submit-button"
        color="warn"
        routerLink="/dashboard/children">
          <span class="text-base">Cancel</span>
      </button>
    </mat-card-footer>  
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>  
  </mat-card>
</div>