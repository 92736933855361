<p class="dense-1">
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Enter Code</mat-label>
    <input 
      matInput 
      type="text"
      required="true" 
      maxlength="6"
      [formControl]="codeControl">
    <mat-hint 
      *ngIf="isConfirm"
      align="end" 
      class="cursor-pointer underline text-indigo-500 decoration-indigo-500"
      (click)="onClickResend()">Resend Code
    </mat-hint>
  </mat-form-field>
</p>
