import { createReducer, on } from '@ngrx/store';

import { User } from 'src/app/shared/models/user.interface';
import * as UserActions from './user.actions';

export const initialUserState: User = {
  id: '',
  username: '',
  parentEmail: '',
  role: '',
  isAuth: false,
  isFetchingChildren: false,
  children: [],
};

export const userReducer = createReducer(
  initialUserState,
  on(UserActions.signInUser, (state, { user }) => ({ 
    ...state, 
    id: user.id,
    username: user.username,
    parentEmail: user.parentEmail,
    role: user.role,
    isAuth: user.isAuth,
    children: [],
  })),
  on(UserActions.signOutUser, (state) => ({ 
    ...state, 
    id: '',
    username: '',
    parentEmail: '',
    role: '',
    isAuth: false,
    children: [],
  })),
  on(UserActions.updateUser, (state, { user }) => ({ 
    ...state, 
    id: user.id,
    username: user.username,
    parentEmail: user.parentEmail,
    role: user.role,
    isAuth: user.isAuth,
    children: [],
  })),
  on(UserActions.updateUserEmail, (state, { email }) => ({ 
    ...state, 
    parentEmail: email, 
  })),
  on(UserActions.updateUserIsFetchingChildrenStatus, (state, { isFetchingChildren }) => ({ 
    ...state, 
    isFetchingChildren: isFetchingChildren, 
  })),
  on(UserActions.updateUserChildren, (state, { children }) => ({ 
    ...state, 
    children: children,
  })),
  on(UserActions.updateUserChild, (state, { children, index }) => ({ 
    ...state, 
    children: [...state.children, children[index]]
  })),
);