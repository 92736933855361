
  <mat-card class="w-80 flex items-center mt-20">
    <mat-card-header>
      <mat-card-title>
        <app-auth-title></app-auth-title>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>  
      <mat-divider></mat-divider>
      <div class="flex justify-center mt-2">
        <span>Enter your email to receive a code</span>
      </div> 
      <div class="flex justify-center w-64">
        <span class="text-red-500">{{ authError }}</span>
      </div> 
      <form [formGroup]="forgotForm" (ngSubmit)="onForgotPassword()">
        <div class="mt-6 w-64">
          <app-username-input [usernameControl]="usernameControl"></app-username-input>
        </div> 
        <div class="flex justify-center">
          <app-submit-button 
            [isDisabled]="isLoading || forgotForm.invalid" 
            [text]="'Send'">
          </app-submit-button>
        </div> 
      </form>
      <mat-divider></mat-divider>
    </mat-card-content>
    <mat-card-footer>
      <div class="flex justify-center mt-4">
        <p>Already have a Code?
          <span 
            class="cursor-pointer underline text-indigo-500 decoration-indigo-500" 
            routerLink="/auth/reset-pw">
            Click Here!
          </span>
        </p>
      </div>
      <div class="flex justify-center">
        <p>Need to Sign In?
          <span 
            class="cursor-pointer underline text-indigo-500 decoration-indigo-500" 
            routerLink="/auth/sign-in">
            Click Here!
          </span>
        </p>
      </div>
    </mat-card-footer>  
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>  
  </mat-card>

