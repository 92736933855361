import { AuthService } from 'src/app/shared/services/auth.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { selectUserRole } from 'src/app/shared/state/user/user.selectors';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  public userRole = '';
    
  constructor(
    private authService: AuthService, 
    private router: Router, 
    private store$: Store,
  ) {
    this.store$.select(selectUserRole)
    .pipe(
      tap((userRole) => { this.userRole = userRole
    })).subscribe();
  }

  public onSignOut(): void {
    this.authService.signOut()
    .then(() => {
      this.router.navigate(['/auth/sign-in']);
    });
  }

}
