<div class="sticky top-0 z-40">
  <mat-toolbar class="mat-elevation-z6" color="primary">
    <div class="cursor-pointer" [routerLink]="userRole === 'parent' ? '/dashboard/children' : '/dashboard/accounts/0'">
      <span class="ml-2 italic">BankIt!</span>
    </div>
    <span class="flex-auto"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onSignOut()">
        <mat-icon>logout</mat-icon>
        <span>Sign Out</span>
      </button>
    </mat-menu>
  </mat-toolbar>
</div>
