export const environment = {
  production: false,
  // For local API development, swap these variables out
  bankitApiLambdaUrl: 'https://cp07n6tvpe.execute-api.us-east-2.amazonaws.com',
  //bankitApiLambdaUrl: 'https://localhost:7091', // DO NOT DEPLOY WITH THIS UNCOMMENTED!!!
  //bankitApiLambdaUrl: 'https://localhost:8080', // DO NOT DEPLOY WITH THIS UNCOMMENTED!!!
  cognito: {
    userPoolId: 'us-east-2_USO64dx0W',
    userPoolWebClientId: '7qebr5sd54i0f3php1cl1kl8ks',
  },
};
