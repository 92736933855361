<mat-card class="w-80 flex items-center mt-20">
  <mat-card-header>
    <mat-card-title>
      <app-auth-title></app-auth-title>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>  
    <mat-divider></mat-divider>
    <div class="flex justify-center  mt-2">
      <span>Enter 6-digit code sent to your email</span>
    </div> 
    <div class="flex justify-center w-64">
      <span class="text-red-500">{{ authError }}</span>
    </div> 
    <form [formGroup]="resetForm" (ngSubmit)="onPasswordReset()">
      <div class="mt-6 w-64">
        <app-username-input [usernameControl]="usernameControl" [hasUserParentEmail]="userParentEmail ? true : false"></app-username-input>
        <app-code-input [codeControl]="codeControl"></app-code-input>
        <app-password-input 
          [passwordControl]="passwordControl" 
          [isDisabled]="isLoading"
          [isCreate]="true">
        </app-password-input>
      </div> 
      <div class="flex justify-center">
        <app-submit-button 
          [isDisabled]="isLoading || resetForm.invalid" 
          [text]="'Reset'">
        </app-submit-button>
      </div> 
    </form>
    <mat-divider></mat-divider>
  </mat-card-content>
  <mat-card-footer>
    <div class="flex justify-center mt-4">
      <p>Need to Request a Code?
        <span 
          class="cursor-pointer underline text-indigo-500 decoration-indigo-500" 
          routerLink="/auth/forgot-pw">
          Click Here!
        </span>
      </p>
    </div>
    <div class="flex justify-center">
      <p>Need to Sign In?
        <span 
          class="cursor-pointer underline text-indigo-500 decoration-indigo-500" 
          routerLink="/auth/sign-in">
          Click Here!
        </span>
      </p>
    </div>
  </mat-card-footer>  
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>  
</mat-card>
