import { AuthData } from 'src/app/shared/models/auth-data.interface';
import { AuthService } from '../../shared/services/auth.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  private authData: AuthData;
  public forgotForm: FormGroup;

  public isLoading= false;
  public authError = '';

  constructor(private authService: AuthService, private router: Router) {
    this.authData = {
      username: '',
      parentEmail: '',
      password: '',
      code: '',
    };

    this.forgotForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
    });
  }

  get usernameControl() { return this.forgotForm.get('username') as FormControl; }  

  public onForgotPassword(): void {
    if(this.forgotForm.valid) {
      this.isLoading = true;
      this.usernameControl.disable();

      this.authData.username = this.usernameControl?.value;
      if (this.usernameControl?.value?.includes('@')) {
        this.authData.parentEmail = this.usernameControl?.value;
      }
  
      this.authService.forgotPassword(this.authData)
      .then(() => {
        this.isLoading = false;
        this.usernameControl.enable();
        this.authError = '';
        this.router.navigate(['/auth/reset-pw']);
      }).catch((error) => {
        this.isLoading = false;
        this.usernameControl.enable();
        this.authError = error
          .toString()
          .split('Error:')
          .pop() ?? 
          'Something went wrong. Please try again.'; 
      });
    }
  }
}
