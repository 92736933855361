import { AuthData } from 'src/app/shared/models/auth-data.interface';
import { AuthService } from '../../shared/services/auth.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordValidators } from 'src/app/shared/utils/password.validators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {
  private authData: AuthData;
  public signUpForm: FormGroup;

  public isLoading = false;
  public authError = '';

  constructor(
    private authService: AuthService, 
    private passwordValidators: PasswordValidators,
    private router: Router, 
  ) {
    this.authData = {
      username: '',
      parentEmail: '',
      password: '',
      code: '',
    }
    
    this.signUpForm = new FormGroup({
      email: new FormControl('', [
        Validators.required, 
        Validators.email,
      ]),
      password: new FormControl('', [
        Validators.required, 
        Validators.minLength(8),
        this.passwordValidators.containsNumber,
        this.passwordValidators.containsSpecialChar,
        this.passwordValidators.containsUppercase,
        this.passwordValidators.containsLowercase,
      ]),
    });
  }

  get emailControl() { return this.signUpForm.get('email') as FormControl; }

  get passwordControl() { return this.signUpForm.get('password') as FormControl; } 
  
  public onSignUp(): void {
    if(this.signUpForm.valid) { 
      this.isLoading = true;
      this.emailControl.disable();
      this.passwordControl.disable();

      this.authData.username = this.emailControl?.value;
      this.authData.parentEmail = this.emailControl?.value;
      this.authData.password = this.passwordControl?.value;

      this.authService.signUpParent(this.authData)
      .then(() => {
        this.isLoading = false;
        this.emailControl.enable();
        this.passwordControl.enable();
        this.authError = '';
        this.passwordControl?.reset();
        this.router.navigate(['/auth/confirm-parent']);
      }).catch((error) => {
        this.isLoading = false;
        this.emailControl.enable();
        this.passwordControl.enable();
        this.passwordControl?.reset();
        this.authError = error
          .toString()
          .split('Error:')
          .pop() ?? 
          'Something went wrong. Please try again.';  
      });
    }
  }
}
