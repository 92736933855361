import { ActivatedRoute, Router } from '@angular/router';
import { Account } from 'src/app/shared/models/account.interface';
import { AuthData } from 'src/app/shared/models/auth-data.interface';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Child } from 'src/app/shared/models/child.interface';
import { ChildService } from 'src/app/shared/services/child.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, of, tap } from 'rxjs';
import { PasswordValidators } from 'src/app/shared/utils/password.validators';
import { Store } from '@ngrx/store';
import { selectUserParentEmail } from 'src/app/shared/state/user/user.selectors';
import * as uuid from 'uuid';

@Component({
  selector: 'app-add-child',
  templateUrl: './add-child.component.html',
  styleUrls: ['./add-child.component.scss']
})
export class AddChildComponent {
  private authData: AuthData;
  private newChild: Child;
  public childIndex: number;
  public addChildForm: FormGroup;
  
  public isLoading = false;
  public addError = '';
  public parentEmail = '';

  constructor(
    private authService: AuthService, 
    private childService: ChildService,
    private passwordValidators: PasswordValidators,
    private route: ActivatedRoute,
    private router: Router,
    private store$: Store,
  ) {
    this.childIndex = parseInt(this.route.snapshot.paramMap.get('childIndex') ?? '0');

    this.store$.select(selectUserParentEmail)
    .pipe(
      tap((email) => { this.parentEmail = email 
    })).subscribe();

    this.newChild = {
      id: '',
      username: '',
      parentEmail: '',
      isEmailConfirmed: false, 
      accounts: [
        {
          id: uuid.v4(),
          sequenceNumber: 1,
          balance: 0,
          name: 'My First Checking Account',
          isActive: true,
          type: 'Checking',
        } as Account
      ],
    } as Child;

    this.authData = {
      username: '',
      parentEmail: '',
      password: '',
      code: '',
    }
    
    this.addChildForm = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required, 
        Validators.minLength(8),
        this.passwordValidators.containsNumber,
        this.passwordValidators.containsSpecialChar,
        this.passwordValidators.containsUppercase,
        this.passwordValidators.containsLowercase,
      ]),
    });
  }

  get usernameControl() { return this.addChildForm.get('username') as FormControl; }

  get passwordControl() { return this.addChildForm.get('password') as FormControl; } 
  
  public onAddChild(): void {
    if(this.addChildForm.valid) { 
      this.isLoading = true;
      this.usernameControl.disable();
      this.passwordControl.disable();

      let rawUsernameValue = this.usernameControl?.value;
      let trimmedUsernameValue = rawUsernameValue.trim();
      let formalUsernameValue = 
        trimmedUsernameValue.charAt(0).toUpperCase() + 
        trimmedUsernameValue.slice(1);
      this.newChild.username = formalUsernameValue;
      this.newChild.parentEmail = this.parentEmail;

      this.authData.username = formalUsernameValue;
      this.authData.parentEmail = this.parentEmail;
      this.authData.password = this.passwordControl?.value;

      this.authService.signUpChild(this.authData)
        .then((authUserId) => {
          this.newChild.id = authUserId;

          this.childService.addChild(this.newChild).pipe(
            tap(() => {
              this.isLoading = false;
              this.usernameControl.enable();
              this.passwordControl.enable();
              this.addChildForm.reset();
              this.addError = '';
              this.childService.getChildren();
              this.router.navigate(['/dashboard/confirm-child', this.childIndex]);
            }),
            catchError((error) => {
              this.setErrorState(error);
              return of(error);
          })).subscribe();
        }).catch((error) => {
          this.setErrorState(error);
        });
    }
  }

  private setErrorState(error: any): void {
    this.isLoading = false;
    this.usernameControl.enable();
    this.passwordControl.enable();
    this.passwordControl.reset();
    this.addError = error
      .toString()
      .split('Error:')
      .pop() ?? 
      'Something went wrong. Please try again.'; 
  }
}
