<div class="flex items-center justify-center h-screen">
  <div class="align-middle text-center">
    <div><span class="text-5xl mb-4">404</span></div>
    <mat-icon>warning_amber</mat-icon>
    <h3>Page Not Found</h3>
    <p>
      <span 
        class="cursor-pointer underline text-indigo-500 decoration-indigo-500" 
        routerLink="/dashboard">
        Back to App!
      </span>
    </p>
  </div>
</div>