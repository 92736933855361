import { Child } from '../../models/child.interface';
import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/shared/models/user.interface';

export const signInUser = createAction(
  '[User State] Sign In User',
  props<{ user: User }>()
);

export const signOutUser = createAction(
  '[User State] Sign Out User',
);

export const updateUser = createAction(
  '[User State] Update User',
  props<{ user: User }>()
);

export const updateUserEmail = createAction(
  '[User State] Update User Email',
  props<{ email: string }>()
);

export const updateUserIsFetchingChildrenStatus = createAction(
  '[User State] Update User IsFetchingChildren Status',
  props<{ isFetchingChildren: boolean }>()
);

export const updateUserChildren = createAction(
  '[User State] Update User Children Array',
  props<{ children: Child[] }>()
);

export const updateUserChild = createAction(
  '[User State] Update User Child',
  props<{ children: Child[], index: number }>()
);

// export const updateUserChildEmailConfirmationStatus = createAction(
//   '[User State] Update User Child Data Email Confirmation Status',
//   props<{ isEmailConfirmed: boolean }>()
// );
