<div class="mr-12">
  <div [ngClass]="innerWidth > 700 ? 'mt-6 ml-6 w-full flex justify-between' : 'mt-6 ml-6 w-full grid place-content-center text-center'">
    <span class="text-2xl">{{ accountName }}</span>
    <p *ngIf="innerWidth <= 700" class="italic">Current Balance: {{ account.balance | currency }}</p>
    <button 
      mat-raised-button
      color="accent"
      [routerLink]="['/dashboard/add-transaction', childIndex, accountIndex]">
      <mat-icon>add</mat-icon>Transaction
    </button>
  </div>
  <div *ngIf="innerWidth > 700" class="ml-12">
    <p class="italic">Current Balance: {{ account.balance | currency }}</p>
  </div>
  <div class="m-6 w-full">
    <mat-divider class="w-full"></mat-divider>
    <div class="flex text-center items-center justify-center w-full mt-6">
      <div>
        <span class="text-lg">Transactions from the past...</span>
          <p class="dense-1">
            <mat-button-toggle-group class="w-80">
              <mat-button-toggle 
                (click)="onSetQueryDayRange(30)" 
                class="w-full"
                checked>
                  30 Days
              </mat-button-toggle>
              <mat-button-toggle 
                (click)="onSetQueryDayRange(60)" 
                class="w-full">
                  60 Days
              </mat-button-toggle>
              <mat-button-toggle 
                (click)="onSetQueryDayRange(90)" 
                class="w-full">
                  90 Days
                </mat-button-toggle>
            </mat-button-toggle-group>
          </p>
      </div>
    </div>
    <div 
      *ngIf="transactions?.length === 0 && !isLoading" 
      class="mt-12 w-full flex flex-wrap text-center justify-center">
      <p>No transactions have been made in the past {{ queryDayRange }} days.</p>
    </div>
    <div class="flex flex-wrap justify-evenly">
      <div [ngClass]="innerWidth > 700 ? 'mt-6 mb-6 w-1/3' : 'mt-6 mb-6 w-5/6'">
        <canvas id="category-pie-chart"></canvas>
      </div>
      <div [ngClass]="innerWidth > 700 ? 'mt-6 mb-6 w-1/3' : 'mt-6 mb-6 w-5/6'">
        <canvas id="balance-line-graph"></canvas>
      </div>
    </div>
    <div class="mt-6">
      <div 
        *ngFor="let transaction of transactions; let transactionIndex = index" 
        [ngClass]="transaction.amount < 0 ? 'm-6 border-2 rounded-md border-red-400' : 'm-6 border-2 rounded-md border-lime-300'">
        <mat-card>
          <mat-card-header>
            <mat-card-title></mat-card-title>
          </mat-card-header>
          <mat-card-content> 
            <div class="felx text-center">
              <span 
                class="text-md font-medium">
                {{ transaction.timestamp | date:'fullDate' }}
            </span>
              <mat-divider></mat-divider>
            </div>
            <div [ngClass]="innerWidth > 700 ? 'mt-2 flex justify-between' : 'mt-2 grid place-content-center text-center'">
              <div>
                <p 
                  class="text-lg">
                  {{ transaction.description }}
                </p>
                <mat-chip class="ml-2 mb-4" color="primary">{{ transaction.category }}</mat-chip>
              </div>
              <div>
                <p class="text-lg font-medium">Amount: 
                  <span [ngClass]="transaction.amount < 0 ? 'text-red-500' : 'text-lime-500'">{{ transaction.amount | currency }}</span>
                </p>
                <p class="text-sm text-gray-500 font-small">Balance: {{ transaction.balance | currency }}</p>
              </div>
            </div>
          </mat-card-content> 
          <mat-card-footer>
          </mat-card-footer>
        </mat-card>
      </div>
    </div>
  </div>
</div>
