<p *ngIf="!hasUserParentEmail" class="dense-1">
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Enter Email</mat-label>
    <input 
      matInput 
      type="text"
      required="true" 
      [formControl]="emailControl">
  </mat-form-field>
</p>

