<div class="mr-12">
  <div [ngClass]="innerWidth > 700 ? 'mt-6 ml-6 w-full flex justify-between' : 'mt-6 ml-6 w-full grid place-content-center'">
    <span class="text-2xl">Your Children</span>
    <button 
      mat-raised-button
      color="accent" 
      [ngClass]="innerWidth > 700 ? '' : 'mt-4'"
      [routerLink]="['/dashboard/add-child', children.length]">
      <mat-icon>add</mat-icon>Child
    </button>
  </div>
  <div class="m-6 w-full">
    <mat-divider class="w-full"></mat-divider>
  </div>
  <div 
    *ngIf="children.length === 0 && !isLoading" 
    class="mt-24 ml-6 w-full flex flex-wrap text-center justify-center">
    <p>No children have been added.</p>
  </div>
  <div *ngFor="let child of children; let childIndex = index" [ngClass]="innerWidth > 700 ? 'm-6 w-full' : 'm-6 w-full text-center'">
    <div>
      <span *ngIf="child.isEmailConfirmed" class="text-xl cursor-pointer text-indigo-500"
      [routerLink]="['/dashboard/accounts', childIndex]">{{ child.username }}</span>
      <span *ngIf="!child.isEmailConfirmed" class="text-xl">{{ child.username }}</span>
    </div>
    <div *ngIf="child.isEmailConfirmed">
      <div 
        *ngFor="let account of child.accounts; let accountIndex = index" 
        [ngClass]="account.balance < 0 ? 'm-6 border-2 rounded-md border-red-400' : 'm-6 border-2 rounded-md border-lime-300'">
        <mat-card>
          <mat-card-header>
            <mat-card-title></mat-card-title>
          </mat-card-header>
          <mat-card-content> 
            <div [ngClass]="innerWidth > 700 ? 'flex justify-between' : 'grid place-content-center text-center'">
              <div>
                <p 
                  class="text-lg cursor-pointer underline text-indigo-500 decoration-indigo-500"
                  [routerLink]="['/dashboard/account', childIndex, accountIndex]">
                  {{ account.name }}
                </p>
                <mat-chip class="ml-2 mb-4" color="primary">{{ account.type }}</mat-chip>
              </div>
              <div class="mt-2">
                <p class="text-lg font-medium">Balance: 
                  <span [ngClass]="account.balance < 0 ? 'text-red-500' : 'text-lime-500'">{{ account.balance | currency }}</span>
                </p>
              </div>
            </div>
          </mat-card-content> 
          <mat-card-footer>
          </mat-card-footer>
        </mat-card>
      </div>
    </div>
    <div *ngIf="!child.isEmailConfirmed">
      <mat-card *ngFor="let account of child.accounts; let accountIndex = index" class="m-6">
        <mat-card-header>
          <mat-card-title></mat-card-title>
        </mat-card-header>
        <mat-card-content> 
          <div [ngClass]="innerWidth > 700 ? 'flex justify-between' : 'grid place-content-center'">
            <div class="mt-2 mb-2">
              <p>{{ child.username }}'s email has not been confirmed. 
                <span 
                  class="cursor-pointer underline text-indigo-500 decoration-indigo-500" 
                  [routerLink]="['/dashboard/confirm-child', childIndex]">
                  Click Here to Confirm!
                </span>
              </p>
            </div>
          </div>
        </mat-card-content> 
        <mat-card-footer>
        </mat-card-footer>
      </mat-card>
    </div>
    <div class="ml-14 mt-6 w-5/6">
      <mat-divider class="w-full"></mat-divider>
    </div>
  </div>
</div>
