import { Account } from 'src/app/shared/models/account.interface';
import { ActivatedRoute } from '@angular/router';
import { Child } from 'src/app/shared/models/child.interface';
import { Component, HostListener, OnInit } from '@angular/core';
import { selectUser } from 'src/app/shared/state/user/user.selectors';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  public childIndex: number;
  public child!: Child;
  public accounts: Account[] = [];

  public childUsername = 'Child';
  public innerWidth = 0;

  constructor(private store$: Store, private route: ActivatedRoute) {
    this.childIndex = parseInt(this.route.snapshot.paramMap.get('childIndex') ?? '0');

    this.store$.select(selectUser)
      .pipe(
        tap((userState) => {
          if (userState?.children.length > 0) {
            this.child = JSON.parse(JSON.stringify(userState.children[this.childIndex]));
            this.childUsername = this.child.username;
            this.accounts = this.child.accounts;
          }
      })).subscribe();
  }

  public ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }
}
