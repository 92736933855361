import { AuthService } from "../shared/services/auth.service";
import { Injectable } from "@angular/core";
import { withLatestFrom } from "rxjs";
import { Router } from "@angular/router";
import { selectUser } from "../shared/state/user/user.selectors";
import { Store } from "@ngrx/store";

@Injectable({
    providedIn: 'root'
  })
export class AuthBlockGuard {
  private isAuth = false;
  private isParent = false;

  constructor( private authService: AuthService, private router: Router, private store$: Store) {
    this.store$.select(selectUser).pipe(
      withLatestFrom((userState) => {
        userState.role === 'parent' ? this.isParent = true : this.isParent = false;
    }))
  }

  async canActivate() {  
    await this.authService.isAuthenticated().then(isAuth => { this.isAuth = isAuth });
    
    // TODO: Figure out how to make this work
    // Currently emits false at first and so blocks under certain circumstances
    // this.store$.select(selectUserAuthStatus)
    // .pipe(
    //   tap((isAuth) => { this.isAuth = isAuth })
    // ).subscribe();

    if(this.isAuth) {
      this.isParent ? 
        this.router.navigate(['/dashboard/children']) : 
        this.router.navigate(['/dashboard/accounts']);
    }

    return !this.isAuth;
  }
}