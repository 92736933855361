import { Account } from 'src/app/shared/models/account.interface';
import { ActivatedRoute } from '@angular/router';
import { Chart } from 'chart.js/auto';
import { Child } from 'src/app/shared/models/child.interface';
import { Component, HostListener, OnInit } from '@angular/core';
import { LoadingSpinnerDialogComponent } from '../shared/loading-spinner-dialog/loading-spinner-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { selectUser } from 'src/app/shared/state/user/user.selectors';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { Transaction } from 'src/app/shared/models/transaction.interface';
import { TransactionService } from 'src/app/shared/services/transaction.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  public childIndex: number;
  public accountIndex: number;
  public child!: Child;
  public account!: Account;
  public balanceLineGraph: any;
  public categoryPieChart: any;

  public isLoading = true;
  public accountName = 'Account';
  public innerWidth = 0;

  public queryDayRange = 30;
  public today = new Date();

  public transactions: Transaction[] = [];

  public transactionSpendingData: {
    label: string;
    amount: number;
  }[] = [];
  public transactionSpendingLabels: string[] = [];
  public transactionSpendingAmounts: number[] = [];

  public transactionDates: string[] = [];
  public transactionBalances: number[] = [];

  constructor(
    private dialog: MatDialog,
    private store$: Store,
    private route: ActivatedRoute,
    public transactionService: TransactionService,
  ) {
    this.childIndex = parseInt(this.route.snapshot.paramMap.get('childIndex') ?? '0');
    this.accountIndex = parseInt(this.route.snapshot.paramMap.get('accountIndex') ?? '0');

    this.store$.select(selectUser)
      .pipe(
        tap((userState) => {
          if (userState?.children.length > 0) {
            this.child = JSON.parse(JSON.stringify(userState.children[this.childIndex]));
            this.account = this.child.accounts[this.accountIndex];
            this.accountName = this.account.name;
          }
      })).subscribe();

    this.fetchTransactions();
  }

  public ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  public onSetQueryDayRange(queryDayRange: number): void {
    if (this.queryDayRange !== queryDayRange) {
      this.queryDayRange = queryDayRange;

      this.resetArrays();

      this.resetCharts();

      this.fetchTransactions();
    }
  }

  private fetchTransactions(): void {
    this.isLoading = true;
    this.showLoadingSpinnerDialog();

    this.transactionService.getTransactions(
      this.account?.id, 
      new Date(new Date().setDate(this.today.getDate() - this.queryDayRange)), 
      // TODO: Find better way to deal with GMT offset
      new Date(new Date().setDate(this.today.getDate() + 2)),
    ).pipe( 
        tap((transactions) => {
          this.isLoading = false;
          this.hideLoadingSpinnerDialog();

          transactions.forEach((transaction: Transaction) => {
            let transactionDate = new Date(transaction?.timestamp).toLocaleDateString('en-us');

            this.transactions.push(transaction);

            if (
              transaction.category !== 'Adjustment' && 
              transaction.category !== 'Allowance' &&
              transaction.amount < 0
            ) {
              if (this.transactionSpendingData.some((c) => c.label === transaction?.category)) {
                const i = this.transactionSpendingData.findIndex((c) => c.label === transaction?.category);
                this.transactionSpendingData[i].amount += transaction.amount;
              } else {
                this.transactionSpendingData.push({
                  label: transaction?.category,
                  amount: transaction.amount,
                })
              } 
            }
            
            this.transactionDates.push(transactionDate.toString());
            this.transactionBalances.push(transaction?.balance ?? 0);
          });

          this.transactionSpendingData.forEach((dataObject) => {
            this.transactionSpendingLabels.push(dataObject.label);
            this.transactionSpendingAmounts.push(dataObject.amount);
          });

          if (transactions.length > 0) {
            this.renderCategoryPieChart();
            this.renderBalanceLineGraph();
          }
        }),
    ).subscribe();
  }

  private resetArrays(): void {
    this.transactions = [];
    this.transactionSpendingData = [];
    this.transactionSpendingLabels = [];
    this.transactionSpendingAmounts = [];
    this.transactionDates = [];
    this.transactionBalances = [];
  }

  private resetCharts(): void {
    this.categoryPieChart?.destroy();
    this.balanceLineGraph?.destroy();
  }

  private renderCategoryPieChart(): void {
    this.categoryPieChart = new Chart("category-pie-chart", {
      type: 'pie',
      data: {
        labels: this.transactionSpendingLabels,
        datasets: [{
          label: 'Amount',
          data: this.transactionSpendingAmounts,
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
          title: {
            display: true,
            text: `Spending Breakdown (Past ${this.queryDayRange} Days)`
          },
          legend: {
            position: 'top',
          },
          tooltip: {
            enabled: true,
            //mode: 'single',
            callbacks: {
                label: function(context) { 
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed !== null) {
                    label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed);
                  }
                  return label;
                }
            },
          },
        },
        scales: {
          y: {
            display: false,
            grid: {
              display: false,
            },
          },
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      }
    });
  }

  private renderBalanceLineGraph(): void {
    this.balanceLineGraph = new Chart("balance-line-graph", {
      type: 'line',
      data: {
        labels: this.transactionDates.reverse(),
        datasets: [{
          //label: 'Balance',
          data: this.transactionBalances.reverse(),
          fill: true,
          borderColor: 'rgb(63, 81, 181)',
          tension: 0.1
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
          title: {
            display: true,
            text: `Balances (Past ${this.queryDayRange} Days)`
          },
          legend: {
            display: false
          },
          tooltip: {
            enabled: true,
            //mode: 'single',
            callbacks: {
              label: function(context) { 
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                }
                return label;
              }
            },
          },
        },
        scales: {
          y: {
            grid: {
              display: false,
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value, index, ticks) {
                  return '$' + value;
              }
            }
          },
          x: {
            display: false,
            grid: {
              display: false,
            },
          },
        },
      }
    });
  }

  private showLoadingSpinnerDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.closeAll();
    this.dialog.open(LoadingSpinnerDialogComponent, dialogConfig);
  }

  private hideLoadingSpinnerDialog() {
    this.dialog.closeAll();
  }
}
