import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class PasswordValidators {
  containsNumber(control: FormControl): { [key: string]: boolean } | null {
    const nameRegexp: RegExp = /\d/;
    if (control.value && nameRegexp.test(control.value)) {
      return null;
    }
    return { containsnumber: true };
  }
  
  containsSpecialChar(control: FormControl): { [key: string]: boolean }  | null {
    const nameRegexp: RegExp = /[^A-Za-z0-9]/;
    if (control.value && nameRegexp.test(control.value)) {
      return null;
    } 
    return { containsspecialchar: true };
  }

  containsUppercase(control: FormControl): { [key: string]: boolean }  | null {
    const nameRegexp: RegExp = /[A-Z]/;
    if (control.value && nameRegexp.test(control.value)) {
      return null;
    } 
    return { containsuppercase: true };
  }

  containsLowercase(control: FormControl): { [key: string]: boolean }  | null {
    const nameRegexp: RegExp = /[a-z]/;
    if (control.value && nameRegexp.test(control.value)) {
      return null;
    } 
    return { containslowercase: true };
  }
}