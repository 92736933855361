import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthData } from 'src/app/shared/models/auth-data.interface';
import { PasswordValidators } from 'src/app/shared/utils/password.validators';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {
  private authData: AuthData;
  public signInForm: FormGroup;

  public isLoading = false;
  public authError = '';

  constructor(
    private authService: AuthService, 
    private router: Router, 
  ) {
    this.authData = {
      username: '',
      parentEmail: '',
      password: '',
      code: '',
    };
    
    this.signInForm = new FormGroup({
      username: new FormControl('', [
        Validators.required, 
      ]),
      password: new FormControl('', [
        Validators.required, 
        Validators.minLength(8),
      ]),
    });
  }

  get usernameControl() { return this.signInForm.get('username') as FormControl; }

  get passwordControl() { return this.signInForm.get('password') as FormControl; }  

  public onSignIn(): void {
    if(this.signInForm.valid) { 
      this.isLoading = true;
      this.usernameControl.disable();
      this.passwordControl.disable();

      this.authData.username = this.usernameControl?.value;
      this.authData.password = this.passwordControl?.value;

      this.authService.signIn(this.authData)
      .then((userRole) => {
        this.isLoading = false;
        this.usernameControl.enable();
        this.passwordControl.enable();
        this.authError = '';
        this.passwordControl?.reset();

        userRole === 'parent' ? 
          this.router.navigate(['/dashboard/children']) : 
          this.router.navigate(['/dashboard/accounts/0']);
      }).catch((error) => {
        this.isLoading = false;
        this.usernameControl.enable();
        this.passwordControl.enable();
        this.passwordControl?.reset();
        this.authError = error
          .toString()
          .split('Error:')
          .pop() ?? 
          'Something went wrong. Please try again.';  
      });
    }
  }
}


