import { ChildService } from '../shared/services/child.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectUser } from '../shared/state/user/user.selectors';
import { tap } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  private userRole = '';

  constructor(
    private childService: ChildService,
    private router: Router, 
    private store$: Store,
  ) {
    this.store$.select(selectUser)
    .pipe(
      tap((userState) => {
        if (userState) this.userRole = userState.role;
    })).subscribe();
  }

  ngOnInit(): void {
    this.childService.getChildren();
    if (this.userRole === 'parent') {
      this.router.navigate(['/dashboard/children']);
    } 
    else {
      this.router.navigate(['/dashboard/accounts/0'])
    }
  }
}
