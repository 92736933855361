import { Account } from 'src/app/shared/models/account.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { Child } from 'src/app/shared/models/child.interface';
import { ChildService } from 'src/app/shared/services/child.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, of, tap } from 'rxjs';
import { selectUser } from 'src/app/shared/state/user/user.selectors';
import { Store } from '@ngrx/store';
import * as uuid from 'uuid';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent {
  private child!: Child;
  private newAccount: Account;
  public childIndex: number;
  public addAccountForm: FormGroup;
  
  public isLoading = false;
  public addError = '';

  constructor(
    private childService: ChildService,
    private route: ActivatedRoute,
    private router: Router,
    private store$: Store,
  ) {
    this.childIndex = parseInt(this.route.snapshot.paramMap.get('childIndex') ?? '0');
    
    this.store$.select(selectUser)
      .pipe(
        tap((userState) => {
          if (userState?.children.length > 0) this.child = JSON.parse(JSON.stringify(userState.children[this.childIndex]));    
      })).subscribe();

    this.newAccount = {
      id: uuid.v4(),
      sequenceNumber: this.child.accounts.length + 1,
      balance: 0,
      name: '',
      isActive: true,
      type: 'Checking',
    } as Account
    
    this.addAccountForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      type: new FormControl(''),
    });
  }

  get nameControl() { return this.addAccountForm.get('name') as FormControl; }

  get typeControl() { return this.addAccountForm.get('type') as FormControl; }
  
  public onAddAccount(): void {
    if(this.addAccountForm.valid) { 
      this.isLoading = true;
      this.nameControl.disable();
      this.typeControl.disable();

      let rawNameValue = this.nameControl?.value;
      let trimmedNameValue = rawNameValue.trim();
      this.newAccount.name = 
        trimmedNameValue.charAt(0).toUpperCase() + 
        trimmedNameValue.slice(1);
      this.newAccount.type = 'Checking';

      this.child.accounts.push(this.newAccount);

      this.childService.updateChild(this.child).pipe(
        tap(() => {
          this.isLoading = false;
          this.nameControl.enable();
          this.typeControl.enable();
          this.nameControl.setErrors(null);
          this.typeControl.setErrors(null);
          this.addAccountForm.reset();
          this.addError = '';
          this.childService.getChildren();
          this.router.navigate(['/dashboard/accounts', this.childIndex]);
        }),
        catchError((error) =>{
          this.isLoading = false;
          this.nameControl.enable();
          this.typeControl.enable();
          this.addError = error
            .toString()
            .split('Error:')
            .pop() ?? 
            'Something went wrong. Please try again.';  
          return of(error)
        })).subscribe();
    }
  }
}
