import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-title',
  templateUrl: './auth-title.component.html',
  styleUrls: ['./auth-title.component.scss']
})
export class AuthTitleComponent {

}
