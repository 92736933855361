import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-spinner-dialog',
  templateUrl: './loading-spinner-dialog.component.html',
  styleUrls: ['./loading-spinner-dialog.component.scss']
})
export class LoadingSpinnerDialogComponent {

}
