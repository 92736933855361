import { AuthData } from 'src/app/shared/models/auth-data.interface';
import { AuthService } from '../../shared/services/auth.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PasswordValidators } from 'src/app/shared/utils/password.validators';
import { Router } from '@angular/router';
import { selectUserParentEmail } from 'src/app/shared/state/user/user.selectors';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  private authData: AuthData;
  public resetForm: FormGroup;

  public isLoading = false;
  public authError = '';
  public userParentEmail = '';

  constructor(
    private authService: AuthService, 
    private passwordValidators: PasswordValidators,
    private router: Router,
    private store$: Store, 
  ) {
    this.store$.select(selectUserParentEmail)
    .pipe(
      tap((email) => { this.userParentEmail = email 
    })).subscribe();

    this.authData = {
      username: '',
      parentEmail: this.userParentEmail,
      password: '',
      code: '',
    };

    this.resetForm = new FormGroup({
      username: new FormControl(this.authData.parentEmail, [Validators.required]),
      code: new FormControl('', [
        Validators.required, 
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern("^[0-9]*$"),
      ]),
      password: new FormControl(this.authData.password, [
        Validators.required, 
        Validators.minLength(8),
        this.passwordValidators.containsNumber,
        this.passwordValidators.containsSpecialChar,
        this.passwordValidators.containsUppercase,
        this.passwordValidators.containsLowercase,
      ]),
    });
  }

  get usernameControl() { return this.resetForm.get('username') as FormControl; }  

  get codeControl() { return this.resetForm.get('code') as FormControl; }

  get passwordControl() { return this.resetForm.get('password') as FormControl; }  

  public onPasswordReset(): void {
    if(this.resetForm.valid) {
      this.isLoading = true;
      this.usernameControl.disable();
      this.codeControl.disable();
      this.passwordControl.disable();

      this.authData.username = this.usernameControl?.value;
      this.authData.code = this.codeControl?.value;
      this.authData.password = this.passwordControl?.value;
  
      this.authService.resetPassword(this.authData)
      .then(() => {
        this.isLoading = false;
        this.usernameControl.enable();
        this.codeControl.enable();
        this.passwordControl.enable();
        this.authError = '';
        this.passwordControl?.reset();
        this.router.navigate(['/auth/sign-in']);
      }).catch((error) => {
        this.isLoading = false;
        this.usernameControl.enable();
        this.codeControl.enable();
        this.passwordControl.enable();
        this.passwordControl?.reset();
        this.authError = error
          .toString()
          .split('Error:')
          .pop() ?? 
          'Something went wrong. Please try again.'; 
      });
    }
  }
}
