<p class="dense-1">
    <mat-form-field class="w-full" appearance="outline">
      <mat-label>Enter Name</mat-label>
      <input 
        matInput 
        type="text"
        required="true" 
        [formControl]="nameControl">
    </mat-form-field>
  </p>
