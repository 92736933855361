import { ActivatedRoute, Router } from '@angular/router';
import { AuthData } from 'src/app/shared/models/auth-data.interface';
import { AuthService } from 'src/app/shared/services/auth.service';
import { catchError, of, tap } from 'rxjs';
import { Child } from 'src/app/shared/models/child.interface';
import { ChildService } from 'src/app/shared/services/child.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { selectUser } from 'src/app/shared/state/user/user.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-confirm-child',
  templateUrl: './confirm-child.component.html',
  styleUrls: ['./confirm-child.component.scss']
})
export class ConfirmChildComponent {
  private authData: AuthData;
  public children: Child[] = [];
  public child!: Child;
  public childIndex: number;
  public confirmChildForm: FormGroup;

  public childUsername = 'Child';
  public isLoading = false;
  public resendSuccessful = false;
  public confirmError = '';

  constructor(
    private authService: AuthService, 
    private childService: ChildService,
    private route: ActivatedRoute,
    private router: Router,
    private store$: Store,
  ) {
    this.childIndex = parseInt(this.route.snapshot.paramMap.get('childIndex') ?? '0');
    
    this.authData = {
      username: '',
      parentEmail: '',
      password: '',
      code: '',
    }

    this.store$.select(selectUser)
    .pipe(
      tap((userState) => {
        if (userState?.children.length > this.childIndex) {
          this.child = JSON.parse(JSON.stringify(userState.children[this.childIndex]));
          this.childUsername = this.child.username;
        }
    })).subscribe();

    this.confirmChildForm = new FormGroup({
      code: new FormControl({ 
        value: '', 
        disabled: false 
      }, [
        Validators.required, 
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern("^[0-9]*$"),
      ]),
    });
  }

  get codeControl() { return this.confirmChildForm.get('code') as FormControl; }

  public onConfirmChild(): void {
    if(this.confirmChildForm.valid) {
      this.isLoading = true;
      this.resendSuccessful = false;
      this.codeControl.disable();

      this.authData.username = this.childUsername;
      this.authData.parentEmail = this.child?.parentEmail;
      this.authData.code = this.codeControl.value;
  
      this.authService.confirmSignUp(this.authData)
      .then(() => {
        this.child.parentEmail = '';
        this.child.isEmailConfirmed = true;

        this.childService.updateChild(this.child).pipe(
          tap(() => {
            this.childUsername = 'Child';
            this.isLoading = false;
            this.codeControl.enable();
            this.confirmError = '';
            this.childService.getChildren();
            this.router.navigate(['/dashboard/children']);
          }),
          catchError((error) => {
            this.setErrorState(error);  
            return of(error);
        })).subscribe();
      }).catch((error) => {
        this.setErrorState(error); 
      });
    }
  }

  public onResendConfirmCode($event: boolean): void {
    if($event) {
      this.isLoading = true;
      this.codeControl.disable();

      this.authData.username = this.child?.username;
  
      this.authService.resendCofirmCode(this.authData)
      .then(() => {
        this.isLoading = false;
        this.codeControl.enable();
        this.confirmError = '';
        this.resendSuccessful = true;
        this.codeControl?.markAllAsTouched();
      }).catch((error) => {
        this.setErrorState(error);  
      });
    }
  }

  private setErrorState(error: any): void {
    this.isLoading = false;
    this.codeControl.enable();
    this.confirmError = error
      .toString()
      .split('Error:')
      .pop() ?? 
      'Something went wrong. Please try again.';  
  }
}
