import { AuthService } from "../shared/services/auth.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
  })
export class AuthAllowGuard {
  private isAuth = false;

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate() {  
    await this.authService.isAuthenticated().then(isAuth => { this.isAuth = isAuth });
    
    // TODO: Figure out how to make this work
    // Currently emits false at first and so blocks under certain circumstances
    // this.store$.select(selectUserAuthStatus)
    // .pipe(
    //   tap((isAuth) => { this.isAuth = isAuth })
    // ).subscribe();

    if(!this.isAuth) {
      this.router.navigate(['/auth/sign-in']);
    }

    return this.isAuth;
  }
}