
import { Observable, catchError, of, tap } from 'rxjs';
import { Child } from '../models/child.interface';
import { environment } from '../../../environments/environment.development';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUser } from '../state/user/user.selectors';
import { updateUserChildren, updateUserIsFetchingChildrenStatus } from '../../shared/state/user/user.actions';
import { User } from '../models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class ChildService {
  private baseUrl = environment.bankitApiLambdaUrl;
  private user!: User;

  constructor(private http: HttpClient, private store$: Store) {
    this.store$.select(selectUser)
    .pipe(
      tap((userState) => {
        this.user = userState;
    })).subscribe();
  }

  // public getChild(childId: string): Observable<any> {
  //   return this.http.get(`/v1/child/${childId}`)
  //     .pipe(
  //       tap((child) => {
  //       let children: Child[] = [];
  //       children.push(child as Child)
        
  //       this.store$.dispatch(
  //         updateUserChildren({ children: children }));
  //     }),
  //     catchError((error) => {
  //       // TODO: Do something with error?
  //       return of(error);
  //   }));
  // }

  public getChildren(): void {
    this.store$.dispatch(updateUserIsFetchingChildrenStatus({ isFetchingChildren: true }));
    
    this.http.get(`${this.baseUrl}/v1/children/${this.user.id}`, { params: { role: this.user.role } })
      .pipe(tap((children) => {
        this.store$.dispatch(
          updateUserIsFetchingChildrenStatus({ isFetchingChildren: false }));
        this.store$.dispatch(
          updateUserChildren({ children: children as Child[] }));
      }),
      catchError((error) => {
        this.store$.dispatch(
          updateUserIsFetchingChildrenStatus({ isFetchingChildren: false }));
        // TODO: Do something with error?
        return of(error);
    })).subscribe();
  }

  public addChild(child: Child): Observable<any> {
    return this.http.post(`${this.baseUrl}/v1/child/${this.user.id}`, child);
  }

  public updateChild(child: Child): Observable<any> {
    return this.http.put(`${this.baseUrl}/v1/child`, child);
  }
}
