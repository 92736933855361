import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-username-input',
  templateUrl: './username-input.component.html',
  styleUrls: ['./username-input.component.scss']
})
export class UsernameInputComponent {
  @Input() usernameControl = new FormControl();
  @Input() hasUserParentEmail = false;
  @Input() isAddChild = false;
}
