
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.development';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Transaction } from '../models/transaction.interface';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  private baseUrl = environment.bankitApiLambdaUrl;

  constructor(private http: HttpClient) {}

  public getBalance(accountId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/v1/balance/${accountId}`);
  }

  public getTransactions(accountId: string, startTimestamp: Date, endTimestamp: Date): Observable<any> {
    const params = new HttpParams()
      .set('startTimestamp', startTimestamp.toDateString())
      .set('endTimestamp', endTimestamp.toDateString());

    return this.http.get(`${this.baseUrl}/v1/transactions/${accountId}`, { params });
  }

  public addTransaction(childId: string, transaction: Transaction): Observable<any> {
    return this.http.post(`${this.baseUrl}/v1/transaction/${childId}`, transaction);
  }

  public updateTransaction(transaction: Transaction): Observable<any> {
    return this.http.put(`${this.baseUrl}/v1/transaction`, transaction);
  }
}
