<div class="flex items-center justify-center">
    <mat-card class="flex items-center m-6 w-80">
      <mat-card-header>
        <mat-card-title>
          <span>Make Transaction</span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content> 
        <mat-divider></mat-divider>
        <div class="flex justify-center mt-2">
          <span *ngIf="typeControl.value == 'withdrawal'">Remove money from account</span>
          <span *ngIf="typeControl.value == 'deposit'">Add money to account</span>
        </div> 
        <div class="flex justify-center w-64">
          <span class="text-red-500">{{ addError }}</span>
        </div>
        <form [formGroup]="addTransactionForm" (ngSubmit)="onAddTransaction()">
          <div class="mt-6 w-64">
            <div class="mb-8">
              <p class="dense-1">
                <mat-button-toggle-group class="w-full" [formControl]="typeControl">
                  <mat-button-toggle class="w-full" value="withdrawal">Withdrawal</mat-button-toggle>
                  <mat-button-toggle class="w-full" value="deposit" [disabled]="!isParent">Deposit</mat-button-toggle>
                </mat-button-toggle-group>
              </p>
            </div>
            <p class="dense-1">
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Enter Description</mat-label>
                <input 
                  matInput 
                  type="text"
                  required="true" 
                  [formControl]="descriptionControl">
              </mat-form-field>
            </p>
            <p *ngIf="typeControl?.value === 'withdrawal'" class="dense-1">
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Select Category</mat-label>
                <mat-select [formControl]="categoryControl">
                  <mat-option *ngFor="let withdrawalCategory of widthdrawalCategories" [value]="withdrawalCategory">
                    {{ withdrawalCategory }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </p>
            <p *ngIf="typeControl?.value === 'deposit'" class="dense-1">
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Select Category</mat-label>
                <mat-select [formControl]="categoryControl">
                  <mat-option *ngFor="let depositCategory of depositCategories" [value]="depositCategory">
                    {{ depositCategory }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </p>
            <p class="dense-1">
              <mat-form-field class="w-full" appearance="outline">
                <mat-label>Enter Amount</mat-label>
                <input 
                  matInput 
                  currencyMask
                  type="text"
                  required="true" 
                  [formControl]="amountControl">
              </mat-form-field>
            </p>
            <div class="flex justify-center">
              <app-submit-button 
                [isDisabled]="isLoading || addTransactionForm.invalid" 
                [text]="'Complete Transaction'">
              </app-submit-button>
            </div>   
          </div>   
        </form>
        <mat-divider></mat-divider>
      </mat-card-content> 
      <mat-card-footer>
        <button 
          mat-stroked-button 
          class="mb-6 mt-6 w-64 submit-button"
          color="warn"
          [routerLink]="['/dashboard/account', childIndex, accountIndex]">
            <span class="text-base">Cancel</span>
        </button>
      </mat-card-footer>  
      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>  
    </mat-card>
  </div>
