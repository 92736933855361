<div class="flex items-center justify-center">
  <mat-card class="flex items-center m-6 w-80">
    <mat-card-header>
      <mat-card-title>
        Add Account
      </mat-card-title>
    </mat-card-header>
    <mat-card-content> 
      <mat-divider></mat-divider>
      <div class="flex justify-center mt-2">
        <span>Create New Account</span>
      </div> 
      <div class="flex justify-center w-64">
        <span class="text-red-500">{{ addError }}</span>
      </div>
      <form [formGroup]="addAccountForm" (ngSubmit)="onAddAccount()">
        <div class="mt-6 w-64">
          <app-name-input [nameControl]="nameControl"></app-name-input>
          <p>
            <mat-label>Select Account Type</mat-label>
            <br />
            <mat-radio-group 
              matInput 
              [formControl]="typeControl">
                <mat-radio-button [checked]="true" value="Checking">Checking</mat-radio-button>
                <mat-radio-button disabled="true" value="Cavings">Savings (Coming Soon!)</mat-radio-button>
                <mat-radio-button disabled="true" value="Investment">Investments (Coming Soon!)</mat-radio-button>
              </mat-radio-group>
          </p>
          <div class="flex justify-center">
            <app-submit-button 
              [isDisabled]="isLoading || addAccountForm.invalid" 
              [text]="'Add Account'">
            </app-submit-button>
          </div>   
        </div>   
      </form>
      <mat-divider></mat-divider>
    </mat-card-content> 
    <mat-card-footer>
      <button 
        mat-stroked-button 
        class="mb-6 mt-6 w-64 submit-button"
        color="warn"
        [routerLink]="['/dashboard/accounts/', childIndex]">
          <span class="text-base">Cancel</span>
      </button>
    </mat-card-footer>  
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>  
  </mat-card>
</div>