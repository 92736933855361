import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';

import { AccountComponent } from './dashboard/account/account.component';
import { AccountsComponent } from './dashboard/accounts/accounts.component';
import { AddAccountComponent } from './dashboard/add-account/add-account.component';
import { AddChildComponent } from './dashboard/add-child/add-child.component';
import { AddTransactionComponent } from './dashboard/add-transaction/add-transaction.component';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { AuthTitleComponent } from './auth/shared/auth-title/auth-title.component';
import { ChildrenComponent } from './dashboard/children/children.component';
import { CodeInputComponent } from './auth/shared/code-input/code-input.component';
import { ConfirmChildComponent } from './dashboard/confirm-child/confirm-child.component';
import { ConfirmParentComponent } from './auth/confirm-parent/confirm-parent.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmailInputComponent } from './auth/shared/email-input/email-input.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { LoadingSpinnerDialogComponent } from './dashboard/shared/loading-spinner-dialog/loading-spinner-dialog.component';
import { NameInputComponent } from './dashboard/shared/name-input/name-input.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { PasswordInputComponent } from './auth/shared/password-input/password-input.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { SubmitButtonComponent } from './shared/components/submit-button/submit-button.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { ToolbarComponent } from './dashboard/toolbar/toolbar.component';
import { UsernameInputComponent } from './shared/components/username-input/username-input.component';

import { TooltipListPipe } from './shared/utils/tooltip-list.pipe';
import { userReducer } from './shared/state/user/user.reducer';
import { JwtInterceptorProvider } from './shared/services/jwt-interceptor.service';

@NgModule({
  declarations: [
    AccountComponent,
    AccountsComponent,
    AddAccountComponent,
    AddChildComponent,
    AddTransactionComponent,
    AppComponent,
    AuthComponent,
    AuthTitleComponent,
    ChildrenComponent,
    CodeInputComponent,
    ConfirmChildComponent,
    ConfirmParentComponent,
    DashboardComponent, 
    EmailInputComponent,  
    FooterComponent,
    ForgotPasswordComponent,
    LoadingSpinnerDialogComponent,
    NameInputComponent,
    NotFoundComponent,
    PasswordInputComponent,
    ResetPasswordComponent,
    SignInComponent,
    SignUpComponent,
    SubmitButtonComponent,
    ToolbarComponent,
    TooltipListPipe,
    UsernameInputComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CurrencyMaskModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    StoreModule.forRoot({ user: userReducer }),
    StoreDevtoolsModule.instrument(),
  ],
  providers: [
    JwtInterceptorProvider,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
