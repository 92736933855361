import { Child } from 'src/app/shared/models/child.interface';
import { Component, HostListener, OnInit } from '@angular/core';
import { LoadingSpinnerDialogComponent } from '../shared/loading-spinner-dialog/loading-spinner-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { selectUser } from 'src/app/shared/state/user/user.selectors';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

@Component({
  selector: 'app-children',
  templateUrl: './children.component.html',
  styleUrls: ['./children.component.scss']
})
export class ChildrenComponent implements OnInit {
  public children: Child[] = [];
  public isLoading = true;
  public innerWidth = 0;

  constructor(
    private dialog: MatDialog,
    private store$: Store,
  ) {
    this.store$.select(selectUser)
    .pipe(
      tap((userState) => {
        if (userState?.isFetchingChildren) {
          this.isLoading = true;
          this.showLoadingSpinnerDialog();
        } else {
          this.isLoading = false;
          this.hideLoadingSpinnerDialog();
        }
        
        if (userState?.children.length > 0) {
          this.children = JSON.parse(JSON.stringify(userState.children));
        }
    })).subscribe();
  }

  public ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.innerWidth = window.innerWidth;
  }

  private showLoadingSpinnerDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.closeAll();
    this.dialog.open(LoadingSpinnerDialogComponent, dialogConfig);
  }

  private hideLoadingSpinnerDialog() {
    this.dialog.closeAll();
  }
}
