<p class="dense-1">
  <mat-form-field class="w-full" appearance="outline">
    <mat-label>Enter Password</mat-label>
    <input 
      matInput 
      required="true"
      [formControl]="passwordControl"
      [type]="showPassword ? 'text' : 'password'">
    <button 
      matSuffix 
      mat-icon-button 
      type="button"
      [disabled]="isDisabled" 
      (click)="onToggleShowPassword()">
        <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-hint 
      *ngIf="!isCreate"
      align="end" 
      class="cursor-pointer underline text-indigo-500 decoration-indigo-500"
      routerLink="/auth/forgot-pw">Forgot Password?
    </mat-hint>
  </mat-form-field>
</p>
<div *ngIf="isCreate" class="mb-2 pl-8 pr-8">
  <p class="text-xs text-gray-500">Password must contain at least...</p>
  <div class="ml-6">
    <span class="text-xs">
      <mat-icon 
        *ngIf="passwordControl.value?.length >= 8"
        class="text-green-500 text-xs shrink-icon align-top">check_circle</mat-icon>
      <mat-icon 
        *ngIf="passwordControl.value?.length < 8" 
        class="text-gray-500 text-xs shrink-icon align-top" >error_outline</mat-icon>
      <span 
        [ngClass]="passwordControl.value?.length >= 8 ? 'text-green-500 text-xs align-top' : 'text-gray-500 text-xs align-top'">
        8 total characters 
        <span *ngIf="passwordControl.value?.length < 8">
          ({{ passwordControl.value?.length }}/8)
        </span>
      </span>
    </span>
    <br />
    <span class="text-xs">
      <mat-icon 
        *ngIf="!passwordContainsNumber" 
        class="text-green-500 text-xs shrink-icon align-top" >check_circle</mat-icon>
      <mat-icon 
        *ngIf="passwordContainsNumber" 
        class="text-gray-500 text-xs shrink-icon align-top">error_outline</mat-icon>
      <span 
        [ngClass]="passwordContainsNumber ? 'text-gray-500 align-top' : 'text-green-500 align-top'">
        1 number
      </span>
    </span>
    <br />
    <span class="text-xs">
      <mat-icon 
        *ngIf="!passwordContainsSpecialChar" 
        class="text-green-500 text-xs shrink-icon align-top">check_circle</mat-icon>
      <mat-icon 
        *ngIf="passwordContainsSpecialChar" 
        class="text-gray-500 text-xs shrink-icon align-top">error_outline</mat-icon>
      <span 
        [ngClass]="passwordContainsSpecialChar ? 'text-gray-500 align-top' : 'text-green-500 align-top'">
        1 special character
      </span>
    </span>
    <br />
    <span class="text-xs">
      <mat-icon 
        *ngIf="!passwordContainsUppercase" 
        class="text-green-500 text-xs shrink-icon align-top" >check_circle</mat-icon>
      <mat-icon 
        *ngIf="passwordContainsUppercase" 
        class="text-gray-500 text-xs shrink-icon align-top" >error_outline</mat-icon>
      <span 
        [ngClass]="passwordContainsUppercase ? 'text-gray-500 align-top' : 'text-green-500 align-top'">
        1 uppercase letter
      </span>
    </span>
    <br />
    <span class="text-xs">
      <mat-icon 
        *ngIf="!passwordContainsLowercase" 
        class="text-green-500 text-xs shrink-icon align-top" >check_circle</mat-icon>
      <mat-icon 
        *ngIf="passwordContainsLowercase" 
        class="text-gray-500 text-xs shrink-icon align-top" >error_outline</mat-icon>
      <span 
        [ngClass]="passwordContainsLowercase ? 'text-gray-500 align-top' : 'text-green-500 align-top'">
        1 lowercase letter
      </span>
    </span>
  </div>
