import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment.development';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isApiUrl = request.url.startsWith(environment.bankitApiLambdaUrl);
    let jwt = '';
    
    Object.keys(localStorage).forEach((key) => {
      if (key.endsWith('.accessToken')) jwt = localStorage.getItem(key) ?? '';
    })
    
    if (jwt && isApiUrl) {
      request = request.clone({
          setHeaders: { Authorization: jwt }
      });
    }

    return next.handle(request);
  }
}

export const JwtInterceptorProvider = { 
  provide: HTTP_INTERCEPTORS, 
  useClass: JwtInterceptor,
  multi: true, 
}