import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthAllowGuard } from './auth/auth-allow.guard';
import { AuthBlockGuard } from './auth/auth-block.guard';

import { AccountComponent } from './dashboard/account/account.component';
import { AccountsComponent } from './dashboard/accounts/accounts.component';
import { AddAccountComponent } from './dashboard/add-account/add-account.component';
import { AddChildComponent } from './dashboard/add-child/add-child.component';
import { AddTransactionComponent } from './dashboard/add-transaction/add-transaction.component';
import { AuthComponent } from './auth/auth.component';
import { ConfirmChildComponent } from './dashboard/confirm-child/confirm-child.component';
import { ChildrenComponent } from './dashboard/children/children.component';
import { ConfirmParentComponent } from './auth/confirm-parent/confirm-parent.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthAllowGuard] },
  { path: 'auth', component: AuthComponent, canActivate: [AuthBlockGuard], children: [
    { path: 'confirm-parent', component: ConfirmParentComponent },
    { path: 'forgot-pw', component: ForgotPasswordComponent },
    { path: 'reset-pw', component: ResetPasswordComponent },
    { path: 'sign-in', component: SignInComponent },
    { path: 'sign-up', component: SignUpComponent },
  ]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthAllowGuard], children: [
    { path: 'account/:childIndex/:accountIndex', component: AccountComponent },
    { path: 'accounts/:childIndex', component: AccountsComponent },
    { path: 'add-account/:childIndex', component: AddAccountComponent },
    { path: 'add-child/:childIndex', component: AddChildComponent },
    { path: 'add-transaction/:childIndex/:accountIndex', component: AddTransactionComponent },
    { path: 'children', component: ChildrenComponent },
    { path: 'confirm-child/:childIndex', component: ConfirmChildComponent },
  ]},
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
