import { AuthData } from 'src/app/shared/models/auth-data.interface';
import { AuthService } from '../../shared/services/auth.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { selectUserParentEmail } from 'src/app/shared/state/user/user.selectors';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';

@Component({
  selector: 'app-confirm-parent',
  templateUrl: './confirm-parent.component.html',
  styleUrls: ['./confirm-parent.component.scss']
})
export class ConfirmParentComponent {
  private authData: AuthData;
  public confirmForm: FormGroup;

  public isLoading = false;
  public resendSuccessful = false;
  public authError = '';
  public userParentEmail = '';

  constructor(
    private authService: AuthService, 
    private router: Router, 
    private store$: Store,
  ) {  
    this.store$.select(selectUserParentEmail)
    .pipe(
      tap((email) => { this.userParentEmail = email 
    })).subscribe();
    
    this.authData = {
      username: '',
      parentEmail: this.userParentEmail,
      password: '',
      code: '',
    };
    
    this.confirmForm = new FormGroup({
      username: new FormControl({ 
        value: this.authData.parentEmail, 
        disabled: false 
      }, [
        Validators.required,
        Validators.email,
      ]),
      code: new FormControl({ 
        value: '', 
        disabled: false 
      }, [
        Validators.required, 
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern("^[0-9]*$"),
      ]),
    });
  }

  get usernameControl() { return this.confirmForm.get('username') as FormControl; }  

  get codeControl() { return this.confirmForm.get('code') as FormControl; }

  public onConfirmSignUp(): void {
    if(this.confirmForm.valid) {
      this.isLoading = true;
      this.resendSuccessful = false;
      this.usernameControl.disable();
      this.codeControl.disable();

      this.authData.username = this.usernameControl?.value;
      this.authData.code = this.codeControl?.value;
  
      this.authService.confirmSignUp(this.authData)
      .then(() => {
        this.isLoading = false;
        this.usernameControl.enable();
        this.codeControl.enable();
        this.authError = '';
        this.router.navigate(['/auth/sign-in']);
      }).catch((error) => {
        this.isLoading = false;
        this.usernameControl.enable();
        this.codeControl.enable();
        this.authError = error
          .toString()
          .split('Error:')
          .pop() ?? 
          'Something went wrong. Please try again.'; 
      });
    }
  }

  public onResendConfirmCode($event: boolean): void {
    if(this.usernameControl?.value && $event) {
      this.isLoading = true;
      this.usernameControl.disable();
      this.codeControl.disable();

      this.authData.username = this.usernameControl?.value;
  
      this.authService.resendCofirmCode(this.authData)
      .then(() => {
        this.isLoading = false;
        this.usernameControl.enable();
        this.codeControl.enable();
        this.authError = '';
        this.resendSuccessful = true;
        this.codeControl?.markAllAsTouched();
      }).catch((error) => {
        this.isLoading = false;
        this.usernameControl.enable();
        this.codeControl.enable();
        this.authError = error
          .toString()
          .split('Error:')
          .pop() ?? 
          'Something went wrong. Please try again.'; 
      });
    } else {
      this.usernameControl?.markAsTouched();
    }
  }
}
