import { Account } from 'src/app/shared/models/account.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { Child } from 'src/app/shared/models/child.interface';
import { ChildService } from 'src/app/shared/services/child.service';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, of, tap } from 'rxjs';
import { selectUser } from 'src/app/shared/state/user/user.selectors';
import { Store } from '@ngrx/store';
import { Transaction } from 'src/app/shared/models/transaction.interface';
import { TransactionService } from 'src/app/shared/services/transaction.service';

@Component({
  selector: 'app-add-transaction',
  templateUrl: './add-transaction.component.html',
  styleUrls: ['./add-transaction.component.scss']
})
export class AddTransactionComponent {
  public childIndex: number;
  public accountIndex: number;
  public child!: Child;
  public account!: Account;
  private newTransaction: Transaction;
  public addTransactionForm: FormGroup;
  public widthdrawalCategories: string[];
  public depositCategories: string[];
  
  public isParent = false;
  public isLoading = false;
  public addError = '';
  public today = new Date();
  public type: 'withdrawal' | 'deposit' = 'withdrawal';

  constructor(
    private childService: ChildService,
    private route: ActivatedRoute,
    private router: Router,
    private store$: Store,
    private transactionService: TransactionService
  ) {
    this.childIndex = parseInt(this.route.snapshot.paramMap.get('childIndex') ?? '0');
    this.accountIndex = parseInt(this.route.snapshot.paramMap.get('accountIndex') ?? '0');

    this.widthdrawalCategories = [
      'Adjustments',
      'Books',
      'Cash Withdrawals',
      'Games',
      'Gifts',
      'Hobbies',
      'Snacks/Treats', 
      'Other',   
    ]

    this.depositCategories = [
      'Adjustments',
      'Allowances',
      'Cash Deposits',
      'Gifts',
      'Other',  
    ]

    this.store$.select(selectUser)
    .pipe(
      tap((userState) => {
        userState.role === 'parent' ? this.isParent = true : this.isParent = false;

        if (userState?.children.length > 0) {
          this.child = JSON.parse(JSON.stringify(userState.children[this.childIndex]));
          this.account = this.child.accounts[this.accountIndex];
        }
    })).subscribe();

    this.newTransaction = {
      accountId: this.account?.id,
      timestamp: this.today,
      category: 'Other',
      description: '',
      status: 'Approved',
      amount: 0,
    } as Transaction;
    
    this.addTransactionForm = new FormGroup({
      type: new FormControl(this.type, [Validators.required]),
      category: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),
      amount: new FormControl('', [Validators.required]),
    });
  }

  get typeControl() { return this.addTransactionForm.get('type') as FormControl; }

  get categoryControl() { return this.addTransactionForm.get('category') as FormControl; }

  get descriptionControl() { return this.addTransactionForm.get('description') as FormControl; }

  get amountControl() { return this.addTransactionForm.get('amount') as FormControl; } 
  
  public onAddTransaction(): void {
    if(this.addTransactionForm.valid) { 
      this.isLoading = true;
      this.categoryControl.disable();
      this.descriptionControl.disable();
      this.amountControl.disable();

      this.newTransaction.category = this.categoryControl?.value;
      let rawDescriptionValue = this.descriptionControl?.value;
      let trimmedDescriptoinValue = rawDescriptionValue.trim();
      this.newTransaction.description = 
        trimmedDescriptoinValue.charAt(0).toUpperCase() + 
        trimmedDescriptoinValue.slice(1);
      this.newTransaction.amount = this.amountControl?.value;

      if (this.typeControl.value === 'withdrawal') 
      {
        this.newTransaction.amount = this.newTransaction.amount * -1;
      }

      this.transactionService.addTransaction(this.child.id, this.newTransaction).pipe(
        tap(() => {
          this.isLoading = false;
          this.categoryControl.enable();
          this.descriptionControl.enable();
          this.amountControl.enable();
          this.addTransactionForm.reset();
          this.addError = '';
          this.childService.getChildren();
          this.router.navigate(['/dashboard/account', this.childIndex, this.accountIndex]);
        }),
        catchError((error) => {
          this.isLoading = false;
          this.categoryControl.enable();
          this.descriptionControl.enable();
          this.amountControl.enable();
          this.addError = error
            .toString()
            .split('Error:')
            .pop() ?? 
            'Something went wrong. Please try again.'; 
          return of(error);
      })).subscribe();
    }
  }
}
