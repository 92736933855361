<p *ngIf="isAddChild" class="dense-1">
  <mat-form-field class="w-full mb-2" appearance="outline">
    <mat-label>Enter Username</mat-label>
    <input 
      matInput 
      type="text"
      required="true" 
      [formControl]="usernameControl">
    <mat-hint class="text-gray-500">Used for child sign in</mat-hint>
  </mat-form-field>
</p>
<p *ngIf="!isAddChild && !hasUserParentEmail" class="dense-1">
  <mat-form-field class="w-full mb-2" appearance="outline">
    <mat-label>Enter Email or Username</mat-label>
    <input 
      matInput 
      type="text"
      required="true" 
      [formControl]="usernameControl">
    <mat-hint class="text-gray-500">Parent: Email&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Child: Username</mat-hint>
  </mat-form-field>
</p>
