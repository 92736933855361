import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss']
})
export class CodeInputComponent {
  @Input() codeControl = new FormControl();
  @Input() isConfirm = false;

  @Output() resendClicked = new EventEmitter<boolean>();

  public onClickResend() {
    this.resendClicked.emit(true);
  }
}
