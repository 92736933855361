import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent {
  @Input() passwordControl = new FormControl();
  @Input() isDisabled = false;
  @Input() isCreate = false;

  public showPassword = false;

  get passwordContainsNumber() { return this.passwordControl.errors?.['containsnumber'] }

  get passwordContainsSpecialChar() { return this.passwordControl.errors?.['containsspecialchar'] }

  get passwordContainsUppercase() { return this.passwordControl.errors?.['containsuppercase'] }

  get passwordContainsLowercase() { return this.passwordControl.errors?.['containslowercase'] }

  public onToggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
