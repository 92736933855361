import { Amplify, Auth } from 'aws-amplify';
import { AuthData } from '../models/auth-data.interface';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment.development';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { signInUser, signOutUser, updateUserEmail } from '../state/user/user.actions';
import { User } from '../models/user.interface'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // This prop is purely for the authGuard
  // TODO: Figure out how to remove/replace this with better solution
  private isAuthBehaviorSubject$: BehaviorSubject<boolean>;

  constructor(private store$: Store) {
    Amplify.configure({
      Auth: environment.cognito,
    });
    
    this.isAuthBehaviorSubject$ = new BehaviorSubject<boolean>(false);
  }

  public signUpParent(authData: AuthData): Promise<any> {
    return Auth.signUp({
      username: authData.username,
      password: authData.password,
      attributes: { 
        email: authData.parentEmail,
        profile: 'parent',
      },
    }).then(() => {
      this.store$.dispatch(
        updateUserEmail({ email: authData.parentEmail }));
    }).catch((error) => {
      throw new Error(this.filterErrorMessage(error));
    });
  }

  public signUpChild(authData: AuthData): Promise<any> {
    return Auth.signUp({
      username: authData.username,
      password: authData.password,
      attributes: { 
        email: authData.parentEmail,
        profile: 'child',
      },
    }).then((authUser) => {
      return authUser.userSub;
    }).catch((error) => {
      throw new Error(this.filterErrorMessage(error));
    });
  }

  public confirmSignUp(authData: AuthData): Promise<any> {
    return Auth.confirmSignUp(authData.username, authData.code)
      .then(() => {})
      .catch((error) => {
        throw new Error(this.filterErrorMessage(error));
      });
  }

  public resendCofirmCode(authData: AuthData): Promise<any> {
    return Auth.resendSignUp(authData.username)
      .then(() => {})
      .catch((error) => {
        throw new Error(this.filterErrorMessage(error));
      });
  }

  public signIn(authData: AuthData): Promise<any> {
    return Auth.signIn(authData.username, authData.password)
    .then((authUser) => {
      this.isAuthBehaviorSubject$.next(true);

      this.store$.dispatch(signInUser({ user: {
        id: authUser.attributes.sub,
        username: authUser.username,
        parentEmail: authUser.attributes.email,
        role: authUser.attributes.profile,
        isAuth: true,
      } as User}));

      return authUser.attributes.profile;
    }).catch((error) => {
      this.store$.dispatch(
        updateUserEmail({ email: authData.parentEmail }));
      throw new Error(this.filterErrorMessage(error));
    });
  }

  public forgotPassword(authData: AuthData): Promise<any> {
    return Auth.forgotPassword(authData.username)
      .then(() => {
        this.store$.dispatch(
          updateUserEmail({ email: authData.parentEmail }));
      })
      .catch((error) => {
        throw new Error(this.filterErrorMessage(error));
      });
  }

  public resetPassword(authData: AuthData): Promise<any> {
    return Auth.forgotPasswordSubmit(authData.username, authData.code, authData.password)
      .then(() => {})
      .catch((error) => {
        throw new Error(this.filterErrorMessage(error));
      });
  }

  public signOut(): Promise<any> {
    return Auth.signOut()
    .then(() => {
      this.isAuthBehaviorSubject$.next(false);
      this.store$.dispatch(signOutUser());
    }).catch(() => {
      // TODO: ADD ERROR HANDLING??
    });
  }

  // This function is purely for the authGuard
  // TODO: Figure out how to remove/replace this with better solution
  public isAuthenticated(): Promise<boolean> {
    if (this.isAuthBehaviorSubject$.value) {
      return Promise.resolve(true);
    } else {
      return this.getAuthUser()
      .then((authUser: any) => {
        if (authUser) {
          return true;
        } else {
          return false;
        }
      }).catch(() => {
        return false;
      });
    }
  }

  public getAuthUser(): Promise<any> {
    return Auth.currentAuthenticatedUser()
    .then((authUser: any) => {
      this.store$.dispatch(signInUser({ user: {
        id: authUser.attributes.sub,
        username: authUser.username,
        parentEmail: authUser.attributes.email,
        role: authUser.attributes.profile,
        isAuth: true,
      } as User}));

      return authUser;
    }).catch(() => {
      // TODO: ADD ERROR HANDLING??
    });
  }

  private filterErrorMessage(authError: string): string {
    if (authError.toString()
      .includes('CodeMismatchException:')
    ) {
        return 'Incorrect verification code. Please try again.';
    } else if (authError.toString()
      .includes('ExpiredCodeException:')
    ) {
        return 'Verification code expired.';
    } else if(authError.toString()
        .includes('NotAuthorizedException:')
      ) {
        return 'Incorrect email or passowrd. Please try again.';
    } else if(authError.toString()
      .includes('UsernameExistsException:')
      ) {
        return 'Username already used. Please try another.';
    } else if(authError.toString()
      .includes('UserNotConfirmedException:')
    ) {
      return 'Account not confirmed. Please confirm account.';
    }  else if(authError.toString() === 'Error: Network error') {
      return 'No network connection. Please try again.';
    } else if (authError.toString()
        .includes('Error:')
      ) {
        return authError.toString()
          .split('Error:')
          .pop() ?? authError;
    } 
    else {
      return authError;
    }
  }
}
