<div class="flex justify-center">
  <mat-card class="flex items-center m-6 w-80">
    <mat-card-header>
      <mat-card-title>          
        <span>Confirm {{ childUsername }}</span>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content> 
      <mat-divider></mat-divider>
      <div class="flex justify-center mt-2">
        <span>Enter 6-digit code sent to your email</span>
      </div> 
      <div class="flex justify-center w-64">
        <span class="text-red-500">{{ confirmError }}</span>
      </div> 
      <form 
        [formGroup]="confirmChildForm" 
        (ngSubmit)="onConfirmChild()">
        <div class="mt-6 w-64">
          <app-code-input 
            (resendClicked)="onResendConfirmCode($event)" 
            [codeControl]="codeControl" 
            [isConfirm]="true">
          </app-code-input>
        </div> 
        <div class="flex justify-center">
          <app-submit-button 
            [isDisabled]="isLoading || confirmChildForm.invalid" 
            [text]="'Confirm'">
          </app-submit-button>
        </div> 
      </form>
      <mat-divider></mat-divider>
    </mat-card-content> 
    <mat-card-footer>
      <button 
        mat-stroked-button 
        class="mb-4 mt-6 w-64 submit-button"
        color="warn"
        routerLink="/dashboard/children">
          <span class="text-base">Cancel</span>
      </button>
      <div class="flex justify-center w-64 mb-4 mt-2">
        <span 
          *ngIf="resendSuccessful" 
          class="text-green-500">
          Code resent! Please check your email.
        </span>
      </div> 
    </mat-card-footer>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  </mat-card>
</div>